$(document).ready(function(){
    $(".btn--forgotPassword").click(function(){
      console.log()
        var username = $("#forgot-email").val().trim();

        $.ajax({
            url:'forgot.php',
            type:'post',
            data:{username:username},
            success:function(response){
                var msg = "";
                if(response == 1){
                  msg = "If an account associated with that email address or username was found, then an email with a password reset link was sent.";

                  $('.modal-loading--forgotPassword').show()
                  $(".modal-response__content").html(msg);
                  $('#forgot-email').hide();

                  setTimeout(function(){
                    $('.modal-loading--forgotPassword').hide();
                  }, 2000)
                }
            }
        });
    });
});
