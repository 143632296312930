$(document).ready(function(){
    $(".btn--login").click(function(){
        var username = $("#username").val().trim();
        var password = $("#password").val().trim();

        $.ajax({
            url:'login.php',
            type:'post',
            data:{username:username,password:password},
            success:function(response){
                var msg = "";
                if(response == 1){
                  msg = "Invalid username or password!";
                  $('.response--login').show();
                  $(".response__content").html(msg);

                  $('.modal-loading--login').show()

                  setTimeout(function(){
                    $('.modal-loading--login').hide();


                      $('.form').addClass('shake');
                      setTimeout(function(){
                        $('.form').removeClass('shake');
                      }, 1000)


                  }, 2000)


                } else {
                }
            }
        });
    });
});
